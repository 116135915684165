.countdown{
  text-align: center;
}
.countdownInner{
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  justify-content: center;
  @media screen and (max-width: 767px){
    grid-gap: 5px;
  }
}
.item{
  display: grid;
  text-align: center;
}
.number{
  padding-left: 25px;
  padding-right: 25px;
  font-size: 50px;
  line-height: 1em;
  font-weight: 500;
  color: var(--fr-color-1);
  /*color: #F26FA3;*/
  @media screen and (max-width: 767px){
    padding-left: 15px;
    padding-right: 15px;
    font-size: 30px;
  }
}
.label{
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1em;
  letter-spacing: .2em;
  @media screen and (max-width: 767px){
    font-size: 12px;
  }
}