@import url('https://fonts.googleapis.com/css2?family=Bentham&family=Lora:ital,wght@0,400..700;1,400..700&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root{
  --ft-ff-1: 'Bentham';
  --ft-ff-2: 'Lora';
  --ft-ff-3: 'Sarabun';

  --fr-color-1: #E40288;
  --fr-color-2: #FFDBE9;
}
.fr-color-1{
  color: var(--fr-color-1);
}

body {
  margin: 0;
  font-family: 'Sarabun',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.header-spase{
  height: clamp(80px, calc(120/1440*100vw), 120px);
}

.h1{

}
.h2{

}
.h3{

}

.header-h2{
  font-size: clamp(16px, calc(40/1440*100vw), 40px);
  font-weight: 600;
  letter-spacing: clamp(1px, calc(3/1440*100vw), 3px);
  line-height: 1.6;
}
.header-h3{
  font-size: clamp(16px, calc(32/1440*100vw), 32px);
  font-weight: 600;
  letter-spacing: clamp(1px, calc(3/1440*100vw), 3px);
  line-height: 1.6;
}

.text-center{
  text-align: center;
}

.desktop-only{
  @media screen and (max-width: 767px){
    display: none !important;
  }
}
.mobile-only{
  @media screen and (min-width: 768px){
    display: none !important;
  }
}

video,
img {
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ft-container{
  padding: 0 clamp(20px, calc(100/1440*100vw), 100px);
  max-width: 1440px;
  margin: 0 auto;
  &.ft-container-p0{
    padding: 0;
  }
}


.ft-front-font{
  font-family: var(--ft-ff-2);
  font-weight: 500;
  color: #1E1E1E;
  line-height: 1;
  position: relative;
  z-index: 3;
  transform: translateY(-130%);
  letter-spacing: min(0.5vw, 9.6px);
}
.ft-back-font{
  font-family: var(--ft-ff-1);
  color: #F9EAEF;
  line-height: 1.37;
  position: relative;
  z-index: 1;
}


.ft-button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 74%;
  font-size: clamp(18px, calc(32/1440*100vw), 32px);
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: clamp(1px, calc(3/1440*100vw), 3px);
  padding: clamp(20px, calc(30/1440*100vw), 30px) 20px;
  text-transform: uppercase;
  text-decoration: none;
  background: #F26FA3;
  color: #fff;
  border-radius: 8px;
  transition: all .5s ease;
  &:hover{
    background: var(--fr-color-1);
  }
}

.ft-front-back-header{
  text-align: center;
}
.ft-front-back-header--567{
  .ft-back-font{
    font-size: min( calc(177/1440*100vw), 177px );
  }
  .ft-front-font{
    font-size: min( calc(76/1440*100vw), 76px );
  }
}

.ft-front-back-header--42342{
  .ft-title-093450{
    font-size: min(52px, calc(52/1440*100vw));
    font-weight: bold;
    line-height: 1.5;
    color: var(--fr-color-1);
    letter-spacing: min(0.5vw, 9.6px);
  }
}

.ft-section-margin{
  margin-top: clamp(20px, calc(70/1440*100vw), 70px) ;
  margin-bottom: clamp(20px, calc(70/1440*100vw), 70px) ;
}
.ft-section-margin-top{
  margin-top: clamp(20px, calc(70/1440*100vw), 70px) ;
}
.ft-section-margin-bottom{
  margin-bottom: clamp(20px, calc(70/1440*100vw), 70px) ;
}




.ft-header{
  position: fixed;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #fff;
  box-shadow: 0 0 60px -24px rgba(146, 47, 81, 0.3);
  .ft-header--top {
    display: grid;
    align-items: center;
    background: #EBC7DC;
    height: clamp(32px, calc(56/1440*100vw), 56px);
    a{
      text-decoration: none;
      font-weight: bold;
      color: #b17d92;
      transition: all .5s ease;
      &:hover{
        color: var(--fr-color-1);
      }
    }
  }
  .ft-header--center{
    position: relative;
    background: #FFF;
  }
  .ft-header--center--inner{
    position: relative;
    display: grid;
    /*grid-template-columns: 1fr clamp(56px, calc(250/1440*100vw), 250px) 1fr;*/
    grid-template-columns: 150px 1fr 150px;
    max-width: 1500px;
    padding: 0 40px;
    margin: 0 auto;
    @media screen and (max-width: 1440px){
      grid-template-columns: 150px 1fr 1px;
    }
    @media screen and (max-width: 992px){
      grid-template-columns: 1px 1fr 1px;
      .header-contact{
        display: none;
      }
    }
  }
  .ft-logo-block{
    img {
      position: relative;
      transform: translateY(10%);
      margin-bottom: -5.5vw;
    }
  }
  .ft-contact-a{
    text-decoration: none;
    color: #b17d92;
    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('https://dreamcatchers.com/cdn/shop/files/headphones.png?7948') no-repeat center center;
      vertical-align: middle;
      margin-top: -4px;
      margin-right: 5px;
    }
  }
}

.ft-footer{
  border-top: 1px solid #fff;
  box-shadow: 0 0 60px -24px rgba(146, 47, 81, 0.3);
  background: #ffffff;
  min-height: 100px;
  padding-top: 60px;
  letter-spacing: .025em;
  line-height: 1.6;
  font-size: 12px;
  @media screen and (max-width: 1100px) {
    .ft-container{
      padding: 0 20px;
    }
  }
  .ft-after{
    position: relative;
    padding-top: 60px;
    &:after {
      content: "";
      width: 100%;
      height: 96px;
      display: block;
      background: url('https://dreamcatchers.com/cdn/shop/files/product-font-bg.png?12951') no-repeat center top;
      background-size: cover;
      top: 6px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
      max-width: 1220px;
    }
  }
  .footer__collapsible > ul {
    display: flex;
    text-align: left;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 960px) {
      justify-content: flex-start;
    }
  }
  .footer-menu-custom {
    margin-bottom: 90px;
  }
  ul{
    padding: 0;
  }
  .footer__collapsible > ul > li {
    padding: 0;
    width: auto;
    margin: 0 50px 0 0;
    @media (max-width: 1100px){
      margin-right: 30px !important;
    }
    @media only screen and (max-width: 959px) {
      flex-basis: 50%;
      margin-right: 0 !important;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 670px){
      flex-basis: 100%;
    }
  }
  .footer-menu-custom{
    @media (max-width: 960px) {
      width: 65%;
      margin: 0 auto;
    }
    @media only screen and (max-width: 670px){
      width: 80%;
      max-width: 200px;
    }
  }
  .footer-menu-custom li {
    display: inline-block;
    //text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .footer__collapsible > ul > li > a {
    font-weight: 900;
    color: #2d2d2d;
    text-decoration: none;
  }
  .footer__collapsible > ul > li > a:after {
    content: "";
    display: block;
    width: 25px;
    height: 3px;
    background: #ef85ae;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .site-footer__linklist a {
    display: block;
    padding: 4px 0;
    color: #2d2d2d;
    text-decoration: none;
  }

  .site-footer a {
    color: #2d2d2d;
  }
  .footer__collapsible > ul > li .child {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  .footer__collapsible > ul > li .child li {
    padding: 0;
    margin: 0;
  }

  .footer-logo-link{
    display: block;
    width: 140px;
    margin: 0 auto 20px; 
  }

  .footer__social{
    text-align: center;
  }

  .footer__social li {
    display: inline-block;
    margin: 0 15px 15px 0;
  }

  .footer__social a {
    display: block;
  }
  .footer__social .icon {
    width: 24px;
    height: 24px;
  }
  .visually-hidden, .icon__fallback-text {
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
  }
  .phone-footer{
    text-align: center;
    color: #2d2d2d;
    font-size: 17px;
    padding-left: 5px;
    b {
      font-weight: 900;
    }
  }
}
.copyright{
  text-align: center;
  font-family: Lato, sans-serif;
  letter-spacing: .025em;
  padding: 25px;
  font-size: 12px;
}


.ft-front-back-header--576167{
  .ft-back-font{
    font-size: min( calc(222/1440*100vw), 222px );
  }
  .ft-front-font{
    font-size: min( calc(90/1440*100vw), 90px );
    letter-spacing: min(0.2vw, 3.6px);
    transform: translateY(-160%);
  }
}
.ft-front-back-header--564537{
  .ft-back-font{
    font-size: min( calc(243/1440*100vw), 243px );
  }
  .ft-front-font{
    font-size: min( calc(76/1440*100vw), 76px );
    transform: translateY(-143%);
    letter-spacing: min(0.35vw, 5.6px);
  }
}
.ft-front-back-header--5857367{
  .ft-back-font{
    font-size: min( calc(250/1440*100vw), 250px );
  }
  .ft-front-font{
    font-size: min( calc(80/1440*100vw), 80px );
    transform: translateY(-170%);
    letter-spacing: min(0.25vw, 3.6px);
  }
}
.ft-front-back-header--55462167{
  .ft-back-font{
    font-size: min( calc(250/1440*100vw), 250px );
  }
  .ft-front-font{
    font-size: min( calc(80/1440*100vw), 80px );
    transform: translateY(-185%);
    letter-spacing: min(0.25vw, 3.6px);
  }
}
.ft-front-back-header--5614377{
  .ft-back-font{
    font-size: min( calc(150/1440*100vw), 150px );
  }
  .ft-front-font{
    font-size: min( calc(80/1440*100vw), 80px );
    transform: translateY(-107%);
    letter-spacing: min(0.25vw, 3.0px);
  }
}









.ft-woobox-form{
  .input-label label{
    color: #000000 !important;
  }
  .input-label label,
  .input-label label{
    font-size: 20px  !important;
    //font-family: 'Sarabun' !important;
    line-height: 1.5 !important;
    font-weight: bold !important;
    letter-spacing: 1px !important;
    @media screen and (max-width: 992px){
      font-size: 14px!important;
    }
  }
  .form-button{
    font-size: 20px !important;
    //font-family: 'Sarabun' !important;
    line-height: 1.5 !important;
    font-weight: bold !important;
    letter-spacing: 1px !important;
    max-width: 80% !important;
    margin-left: auto !important;
    margin-right:auto !important;
    border-radius: 5px !important;
    @media screen and (max-width: 992px){
      font-size: 14px!important;
    }
  }
  .ft-erthtrsrsyhr{
    padding-bottom: 10px !important;
    font-size: 16px !important;
    //font-family: 'Sarabun' !important;
    line-height: 1.5 !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    color:#E381A8 !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 992px){
      font-size: 12px!important;
    }
  }
  #page-component-footer{
    display: none!important;
  }
}


.Swiper-judges{
  background: var(--fr-color-2);
  .swiper-button-next,
  .swiper-button-prev {
    --swiper-navigation-color: #fff;
    width: clamp(26px, calc(85/1440*100vw), 85px);
    height: 100%;
    top: 0;
    margin-top: 0;
    background: #FFA8CB;
    &:after{
      @media screen and (max-width: 767px){
        font-size: 20px;
      }
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}



.ft-menu-part{
  display: grid;
  align-items: center;
}
.ft-menu-part .left-menu{
  display: grid;
  grid-gap: 17px;
  align-self: center;
  text-align: right;
}
.ft-menu-part .right-menu{
  display: grid;
  grid-gap: clamp(5px, calc(17/1440*100vw), 17px);
  align-self: center;
  text-align: left;
}
.ft-menu-part a{
  color: #1E1E1E;
  font-size: clamp(16px, calc(20/1440*100vw), 30px);
  font-weight: bold;
  text-decoration: none;
  transition: all .5s ease;
  padding: 5px 25px;
}
.ft-menu-part  a:hover{
  color: var(--fr-color-1);
}



.header-menu{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  a{
    color: #2d2d2d;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    z-index: 3;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    padding: 7.5px 15px;
    white-space: nowrap;
    &:hover{
      color: var(--fr-color-1);
    }
    &.ft-certified{
      background-color: #ef83ad !important;
      color: #fff !important;
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      font-weight: 800;
      margin-right: 10px;
    }
    &.logo-link{
      img{
        width: 80px;
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }

    }
  }
}






