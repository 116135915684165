.home{
  background: darkgrey;
}



.slider1 {
  user-select: none;
  .sliderItem{
    display: grid;
    padding: 0 clamp(40px, calc(100/1440*100vw), 140px);
    background: var(--fr-color-2);
  }

  .sliderItemInner{
    display: grid;
    grid-template-columns: 400fr 810fr;
    @media screen and (max-width: 767px){
      grid-template-columns: 100%;
    }
  }

  .imageBlock{
    @media screen and (max-width: 767px){
      text-align: center;
      padding-top: 20px;
    }
    .imageBlockInner{
      display: flex;
      position: relative;
      padding-top: calc(420/400*100%);
      overflow: hidden;
    }
    img{
      position: absolute;
      z-index: 4;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      object-position: center;
      object-fit: cover;
      transform: translate(-50%,-50%);
    }
  }
  .textBlock{
    display: grid;
    align-items: center;
    padding-left: clamp(20px, calc(80/1440*100vw), 80px);
    padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: 767px){
      text-align: center;
      padding-left: 0;
    }
  }
  .title{
    font-size: clamp(16px, calc(40/1440*100vw), 40px);
    font-weight: bold;
    letter-spacing: clamp(2px, calc(10/1440*100vw), 10px);
    color: #1E1E1E;
  }
  .text{
    margin-top: 5px;
    font-size: clamp(14px, calc(30/1440*100vw), 30px);
    font-weight: bold;
    letter-spacing: clamp(1px, calc(7/1440*100vw), 7px);
    color: #6C2650;
  }
}



.block343{
  background: var(--fr-color-2);
  padding: clamp(20px, calc(70/1440*100vw), 70px);
  border-radius: 8px;
  font-family: var(--ft-ff-2);
  font-weight: 600;
  letter-spacing: clamp(1px, calc(7/1440*100vw), 7px);
  text-transform: uppercase;
  .t1{
    font-size: clamp(14.5px, calc(40/1440*100vw), 40px);
    margin-bottom: 15px;
  }
  .t2{
    font-size: clamp(16px, calc(63/1440*100vw), 63px);
    color: var(--fr-color-1);
  }
  .t3{
    font-size: clamp(14px, calc(43/1440*100vw), 43px);
    font-style: italic;
    color: var(--fr-color-1);
    margin-top: 5px;
  }
  .t4{
    font-size: clamp(16px, calc(34/1440*100vw), 34px);
    letter-spacing: clamp(1px, calc(3/1440*100vw), 3px);
    margin-top: 20px;
  }
}


.block3433{
  .t5{
    font-family: var(--ft-ff-2);
    font-weight: 600;
    font-size: clamp(16px, calc(28/1440*100vw), 28px);
    letter-spacing: clamp(1px, calc(3/1440*100vw), 3px);
    text-transform: uppercase;
  }
}




.worksContainer{
  @media screen and (max-width: 767px){
    padding: 0;
  }
  .worksContainerInner{
    display: grid;
    grid-gap: clamp(20px, calc(50/1440*100vw), 50px);
    .item{
      text-align: center;
      padding: clamp(20px, calc(70/1440*100vw), 70px);
      background: var(--fr-color-2);
      font-size: clamp(16px, calc(30/1440*100vw), 30px);
      letter-spacing: clamp(1px, calc(3/1440*100vw), 3px);
      line-height: 1.6;
      span{
        font-weight: bold;
      }
    }
  }
}





.prizesContainer {
  margin-top: clamp(20px, calc(140/1440*100vw), 140px) ;
  .row1 {
    padding: clamp(20px, calc(70 / 1440 * 100vw), 70px) 0;
    width: 58%;
    text-align: center;
    font-family: var(--ft-ff-2);
    font-weight: 500;
    line-height: 1;
    letter-spacing: clamp(1px, calc(3 / 1440 * 100vw), 3px);
    text-transform: uppercase;
    @media screen and (max-width: 767px){
      width: 100%;
    }

    .k1 {
      font-size: clamp(24px, calc(99 / 1440 * 100vw), 99px);
      color: #e40288;
    }

    .k2 {
      font-size: clamp(24px, calc(120 / 1440 * 100vw), 120px);
    }

    .k3 {
      font-size: clamp(24px, calc(150 / 1440 * 100vw), 150px);
    }
  }
}

.prizesContainer2{
  padding: clamp(20px, calc(56 / 1440 * 100vw), 56px);
  padding-bottom: 0;
  .row2{
    display: grid;
    grid-template-columns: 856fr 416fr;
    grid-gap: clamp(15px, calc(56 / 1440 * 100vw), 56px);
    @media screen and (max-width: 767px){
      grid-template-columns: 1fr;
    }

    .prizesList{
      display: grid;
      grid-gap: 27px;
      height: max-content;
      line-height: 1;
      font-weight: bold;
      @media screen and (max-width: 767px){
        grid-gap: 15px;
      }
      .block{
        --b-p-box: clamp(30px, calc(56 / 1440 * 100vw), 56px);
        display: grid;
        grid-template-columns: clamp(140px, calc(370 / 1440 * 100vw), 370px) 1fr;
        background: #EBC7DC;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .b1{
          color: #fff;
          padding: var(--b-p-box);
          border-radius: 8px;
        }
        .b2{
          padding: var(--b-p-box);
          padding-left:  clamp(30px, calc(65 / 1440 * 100vw), 75px);
          padding-right: 0;
          text-align: left;
          @media screen and (max-width: 767px){
            padding-left: 0;
            text-align: center;
          }
        }
      }
      .block1{
        .b1{
          background: var(--fr-color-1);
        }
        .t{
          font-size: clamp(25px, calc(70 / 1440 * 100vw), 70px);
        }
        .r{
          font-size: clamp(25px, calc(55 / 1440 * 100vw), 55px);
        }
        .s{
          font-size: clamp(30px, calc(80 / 1440 * 100vw), 80px);
        }
      }
      .block2{
        width: calc(100% - var(--b-p-box));
        @media screen and (max-width: 767px){
          width: 100%;
        }
        .b1{
          background: #F26FA3;
        }
        .t{
          font-size: clamp(25px, calc(65 / 1440 * 100vw), 65px);
        }
        .r{
          font-size: clamp(25px, calc(50 / 1440 * 100vw), 50px);
        }
        .s{
          font-size: clamp(30px, calc(70 / 1440 * 100vw), 70px);
        }
      }
      .block3{
        width: calc(100% - var(--b-p-box) * 2);
        @media screen and (max-width: 767px){
          width: 100%;
          text-align: center;
        }
        .b1{
          background: #F4AAC6;
        }
        .t{
          font-size: clamp(25px, calc(65 / 1440 * 100vw), 65px);
        }
        .r{
          font-size: clamp(25px, calc(50 / 1440 * 100vw), 50px);
        }
        .s{
          font-size: clamp(30px, calc(65 / 1440 * 100vw), 65px);
        }
      }
    }
    .imageBlock{
      img{
        position: relative;
        z-index: 3;
        margin-top: calc( clamp(30px, calc(618 / 1440 * 100vw), 618px) * -1);
      }
    }
  }
}