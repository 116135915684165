@import url("https://fonts.googleapis.com/css2?family=Bentham&family=Lora:ital,wght@0,400..700;1,400..700&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
:root {
  --ft-ff-1: "Bentham";
  --ft-ff-2: "Lora";
  --ft-ff-3: "Sarabun";
  --fr-color-1: #E40288;
  --fr-color-2: #FFDBE9;
}

.fr-color-1 {
  color: var(--fr-color-1);
}

body {
  margin: 0;
  font-family: "Sarabun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
}
body * {
  box-sizing: border-box;
}

.header-spase {
  height: clamp(80px, 8.3333333333vw, 120px);
}

.header-h2 {
  font-size: clamp(16px, 2.7777777778vw, 40px);
  font-weight: 600;
  letter-spacing: clamp(1px, 0.2083333333vw, 3px);
  line-height: 1.6;
}

.header-h3 {
  font-size: clamp(16px, 2.2222222222vw, 32px);
  font-weight: 600;
  letter-spacing: clamp(1px, 0.2083333333vw, 3px);
  line-height: 1.6;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}

video,
img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ft-container {
  padding: 0 clamp(20px, 6.9444444444vw, 100px);
  max-width: 1440px;
  margin: 0 auto;
}
.ft-container.ft-container-p0 {
  padding: 0;
}

.ft-front-font {
  font-family: var(--ft-ff-2);
  font-weight: 500;
  color: #1E1E1E;
  line-height: 1;
  position: relative;
  z-index: 3;
  transform: translateY(-130%);
  letter-spacing: min(0.5vw, 9.6px);
}

.ft-back-font {
  font-family: var(--ft-ff-1);
  color: #F9EAEF;
  line-height: 1.37;
  position: relative;
  z-index: 1;
}

.ft-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 74%;
  font-size: clamp(18px, 2.2222222222vw, 32px);
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: clamp(1px, 0.2083333333vw, 3px);
  padding: clamp(20px, 2.0833333333vw, 30px) 20px;
  text-transform: uppercase;
  text-decoration: none;
  background: #F26FA3;
  color: #fff;
  border-radius: 8px;
  transition: all 0.5s ease;
}
.ft-button:hover {
  background: var(--fr-color-1);
}

.ft-front-back-header {
  text-align: center;
}

.ft-front-back-header--567 .ft-back-font {
  font-size: min(12.2916666667vw, 177px);
}
.ft-front-back-header--567 .ft-front-font {
  font-size: min(5.2777777778vw, 76px);
}

.ft-front-back-header--42342 .ft-title-093450 {
  font-size: min(52px, 3.6111111111vw);
  font-weight: bold;
  line-height: 1.5;
  color: var(--fr-color-1);
  letter-spacing: min(0.5vw, 9.6px);
}

.ft-section-margin {
  margin-top: clamp(20px, 4.8611111111vw, 70px);
  margin-bottom: clamp(20px, 4.8611111111vw, 70px);
}

.ft-section-margin-top {
  margin-top: clamp(20px, 4.8611111111vw, 70px);
}

.ft-section-margin-bottom {
  margin-bottom: clamp(20px, 4.8611111111vw, 70px);
}

.ft-header {
  position: fixed;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #fff;
  box-shadow: 0 0 60px -24px rgba(146, 47, 81, 0.3);
}
.ft-header .ft-header--top {
  display: grid;
  align-items: center;
  background: #EBC7DC;
  height: clamp(32px, 3.8888888889vw, 56px);
}
.ft-header .ft-header--top a {
  text-decoration: none;
  font-weight: bold;
  color: #b17d92;
  transition: all 0.5s ease;
}
.ft-header .ft-header--top a:hover {
  color: var(--fr-color-1);
}
.ft-header .ft-header--center {
  position: relative;
  background: #FFF;
}
.ft-header .ft-header--center--inner {
  position: relative;
  display: grid;
  /*grid-template-columns: 1fr clamp(56px, calc(250/1440*100vw), 250px) 1fr;*/
  grid-template-columns: 150px 1fr 150px;
  max-width: 1500px;
  padding: 0 40px;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
  .ft-header .ft-header--center--inner {
    grid-template-columns: 150px 1fr 1px;
  }
}
@media screen and (max-width: 992px) {
  .ft-header .ft-header--center--inner {
    grid-template-columns: 1px 1fr 1px;
  }
  .ft-header .ft-header--center--inner .header-contact {
    display: none;
  }
}
.ft-header .ft-logo-block img {
  position: relative;
  transform: translateY(10%);
  margin-bottom: -5.5vw;
}
.ft-header .ft-contact-a {
  text-decoration: none;
  color: #b17d92;
}
.ft-header .ft-contact-a:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("https://dreamcatchers.com/cdn/shop/files/headphones.png?7948") no-repeat center center;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 5px;
}

.ft-footer {
  border-top: 1px solid #fff;
  box-shadow: 0 0 60px -24px rgba(146, 47, 81, 0.3);
  background: #ffffff;
  min-height: 100px;
  padding-top: 60px;
  letter-spacing: 0.025em;
  line-height: 1.6;
  font-size: 12px;
}
@media screen and (max-width: 1100px) {
  .ft-footer .ft-container {
    padding: 0 20px;
  }
}
.ft-footer .ft-after {
  position: relative;
  padding-top: 60px;
}
.ft-footer .ft-after:after {
  content: "";
  width: 100%;
  height: 96px;
  display: block;
  background: url("https://dreamcatchers.com/cdn/shop/files/product-font-bg.png?12951") no-repeat center top;
  background-size: cover;
  top: 6px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  max-width: 1220px;
}
.ft-footer .footer__collapsible > ul {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 960px) {
  .ft-footer .footer__collapsible > ul {
    justify-content: flex-start;
  }
}
.ft-footer .footer-menu-custom {
  margin-bottom: 90px;
}
.ft-footer ul {
  padding: 0;
}
.ft-footer .footer__collapsible > ul > li {
  padding: 0;
  width: auto;
  margin: 0 50px 0 0;
}
@media (max-width: 1100px) {
  .ft-footer .footer__collapsible > ul > li {
    margin-right: 30px !important;
  }
}
@media only screen and (max-width: 959px) {
  .ft-footer .footer__collapsible > ul > li {
    flex-basis: 50%;
    margin-right: 0 !important;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 670px) {
  .ft-footer .footer__collapsible > ul > li {
    flex-basis: 100%;
  }
}
@media (max-width: 960px) {
  .ft-footer .footer-menu-custom {
    width: 65%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 670px) {
  .ft-footer .footer-menu-custom {
    width: 80%;
    max-width: 200px;
  }
}
.ft-footer .footer-menu-custom li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.ft-footer .footer__collapsible > ul > li > a {
  font-weight: 900;
  color: #2d2d2d;
  text-decoration: none;
}
.ft-footer .footer__collapsible > ul > li > a:after {
  content: "";
  display: block;
  width: 25px;
  height: 3px;
  background: #ef85ae;
  margin-top: 8px;
  margin-bottom: 4px;
}
.ft-footer .site-footer__linklist a {
  display: block;
  padding: 4px 0;
  color: #2d2d2d;
  text-decoration: none;
}
.ft-footer .site-footer a {
  color: #2d2d2d;
}
.ft-footer .footer__collapsible > ul > li .child {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.ft-footer .footer__collapsible > ul > li .child li {
  padding: 0;
  margin: 0;
}
.ft-footer .footer-logo-link {
  display: block;
  width: 140px;
  margin: 0 auto 20px;
}
.ft-footer .footer__social {
  text-align: center;
}
.ft-footer .footer__social li {
  display: inline-block;
  margin: 0 15px 15px 0;
}
.ft-footer .footer__social a {
  display: block;
}
.ft-footer .footer__social .icon {
  width: 24px;
  height: 24px;
}
.ft-footer .visually-hidden, .ft-footer .icon__fallback-text {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}
.ft-footer .phone-footer {
  text-align: center;
  color: #2d2d2d;
  font-size: 17px;
  padding-left: 5px;
}
.ft-footer .phone-footer b {
  font-weight: 900;
}

.copyright {
  text-align: center;
  font-family: Lato, sans-serif;
  letter-spacing: 0.025em;
  padding: 25px;
  font-size: 12px;
}

.ft-front-back-header--576167 .ft-back-font {
  font-size: min(15.4166666667vw, 222px);
}
.ft-front-back-header--576167 .ft-front-font {
  font-size: min(6.25vw, 90px);
  letter-spacing: min(0.2vw, 3.6px);
  transform: translateY(-160%);
}

.ft-front-back-header--564537 .ft-back-font {
  font-size: min(16.875vw, 243px);
}
.ft-front-back-header--564537 .ft-front-font {
  font-size: min(5.2777777778vw, 76px);
  transform: translateY(-143%);
  letter-spacing: min(0.35vw, 5.6px);
}

.ft-front-back-header--5857367 .ft-back-font {
  font-size: min(17.3611111111vw, 250px);
}
.ft-front-back-header--5857367 .ft-front-font {
  font-size: min(5.5555555556vw, 80px);
  transform: translateY(-170%);
  letter-spacing: min(0.25vw, 3.6px);
}

.ft-front-back-header--55462167 .ft-back-font {
  font-size: min(17.3611111111vw, 250px);
}
.ft-front-back-header--55462167 .ft-front-font {
  font-size: min(5.5555555556vw, 80px);
  transform: translateY(-185%);
  letter-spacing: min(0.25vw, 3.6px);
}

.ft-front-back-header--5614377 .ft-back-font {
  font-size: min(10.4166666667vw, 150px);
}
.ft-front-back-header--5614377 .ft-front-font {
  font-size: min(5.5555555556vw, 80px);
  transform: translateY(-107%);
  letter-spacing: min(0.25vw, 3px);
}

.ft-woobox-form .input-label label {
  color: #000000 !important;
}
.ft-woobox-form .input-label label,
.ft-woobox-form .input-label label {
  font-size: 20px !important;
  line-height: 1.5 !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}
@media screen and (max-width: 992px) {
  .ft-woobox-form .input-label label,
  .ft-woobox-form .input-label label {
    font-size: 14px !important;
  }
}
.ft-woobox-form .form-button {
  font-size: 20px !important;
  line-height: 1.5 !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
  max-width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 5px !important;
}
@media screen and (max-width: 992px) {
  .ft-woobox-form .form-button {
    font-size: 14px !important;
  }
}
.ft-woobox-form .ft-erthtrsrsyhr {
  padding-bottom: 10px !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  letter-spacing: 1px !important;
  color: #E381A8 !important;
  text-transform: uppercase !important;
}
@media screen and (max-width: 992px) {
  .ft-woobox-form .ft-erthtrsrsyhr {
    font-size: 12px !important;
  }
}
.ft-woobox-form #page-component-footer {
  display: none !important;
}

.Swiper-judges {
  background: var(--fr-color-2);
}
.Swiper-judges .swiper-button-next,
.Swiper-judges .swiper-button-prev {
  --swiper-navigation-color: #fff;
  width: clamp(26px, 5.9027777778vw, 85px);
  height: 100%;
  top: 0;
  margin-top: 0;
  background: #FFA8CB;
}
@media screen and (max-width: 767px) {
  .Swiper-judges .swiper-button-next:after,
  .Swiper-judges .swiper-button-prev:after {
    font-size: 20px;
  }
}
.Swiper-judges .swiper-button-prev {
  left: 0;
}
.Swiper-judges .swiper-button-next {
  right: 0;
}

.ft-menu-part {
  display: grid;
  align-items: center;
}

.ft-menu-part .left-menu {
  display: grid;
  grid-gap: 17px;
  align-self: center;
  text-align: right;
}

.ft-menu-part .right-menu {
  display: grid;
  grid-gap: clamp(5px, 1.1805555556vw, 17px);
  align-self: center;
  text-align: left;
}

.ft-menu-part a {
  color: #1E1E1E;
  font-size: clamp(16px, 1.3888888889vw, 30px);
  font-weight: bold;
  text-decoration: none;
  transition: all 0.5s ease;
  padding: 5px 25px;
}

.ft-menu-part a:hover {
  color: var(--fr-color-1);
}

.header-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}
.header-menu a {
  color: #2d2d2d;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  z-index: 3;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 7.5px 15px;
  white-space: nowrap;
}
.header-menu a:hover {
  color: var(--fr-color-1);
}
.header-menu a.ft-certified {
  background-color: #ef83ad !important;
  color: #fff !important;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  font-weight: 800;
  margin-right: 10px;
}
.header-menu a.logo-link img {
  width: 80px;
  max-width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

